import { FILES } from '@/constants/files/files.constants';
import { directusService } from '@/services/api.service';
import { DirectusFile } from '@/types/files/files.types';

/**
 * Returns an array of file objects from directus
 */
export const getFileObjects = async (fileIds: string[]) => {
  const query = {
    query: {
      filter: {
        id: {
          _in: fileIds,
        },
      },
      fields: FILES,
    },
  };
  const req = await directusService({
    /**
     * Directus uses draft "SEARCH" spec to enable large
     * query params in the request body thus assert as 'POST'
     * on Axios
     * @see https://docs.directus.io/reference/introduction.html#search-http-method
     */
    method: 'SEARCH',
    url: '/files',
    data: query,
  });

  return req.data as DirectusFile[];
};

/**
 * Returns an array of file objects from directus
 */
export const getFileObjectsChunked = async (
  fileIdList: string[],
  chunkSize = 40
) => {
  const fileIds = fileIdList.filter((fileId) => fileId && fileId !== 'null');
  const promises = [];

  for (let i = 0; i < fileIds.length; i += chunkSize) {
    const chunk = fileIds.slice(i, i + chunkSize);

    const req = directusService.get('/files', {
      params: {
        filter: {
          id: {
            _in: chunk,
          },
        },
        fields: FILES,
      },
    });

    promises.push(req);
  }

  const results = await Promise.all(promises);
  const files = results.reduce((list, result) => {
    return [...list, ...result.data];
  }, []) as DirectusFile[];

  return files;
};

/**
 * Update a file object on Directus
 * @todo Support more file attributes
 */
export const updateFileObject = async (
  id: string,
  data: Omit<DirectusFile, 'id'>
) => {
  if (!id) {
    return null;
  }
  const req = await directusService.patch(`/files/${id}`, data);

  return req.data as DirectusFile;
};
