import {
  DuoportaFeature,
  DuoportaFeatureDTO,
  DuoportaSpecification,
  DuoportaSpecificationDTO,
} from '@/types/duoporta/duoporta.types';

const mapDuoportaSpecificationData = (
  data: DuoportaSpecificationDTO
): DuoportaSpecification => {
  return {
    id: data.id,
    name: data.name,
    value: data.value,
  };
};

const mapDuoportaFeatures = (data: DuoportaFeatureDTO): DuoportaFeature => {
  return {
    id: data.id,
    name: data.name,
    value: data.value,
  };
};

export { mapDuoportaSpecificationData, mapDuoportaFeatures };
