import {
  duoportaSpecificationNames,
  NEW_CARS_FIELDS,
  NEW_CARS_FIELDS_FOR_OVERVIEW,
  NEW_CARS_FILTER,
  NEW_CARS_LIST_FIELDS,
  NEW_CARS_MODEL_FIELDS,
} from '@/constants/new-cars/new-cars.constants';
import { FOR_SALE, KIA_RETAIL_GROUP } from '@/constants/vars.constants';
import { apiService, directusService, limiter } from '@/services/api.service';
import { getImageId, getImageLink } from '@/services/link.service';
import {
  DuoportaFeature,
  DuoportaFeatureDTO,
  DuoportaSpecification,
  DuoportaSpecificationDTO,
} from '@/types/duoporta/duoporta.types';
import { DirectusFile } from '@/types/files/files.types';
import {
  NewCar,
  NewCarData,
  NewCarOverview,
} from '@/types/new-cars/new-cars.types';
import { Filter } from '@/types/utils/directus.types';

import {
  mapDuoportaFeatures,
  mapDuoportaSpecificationData,
} from './duoporta.service';
import { getFileObjectsChunked } from './files/files.service';

export const getNewCars = limiter.wrap(async () => {
  const { data }: { data: NewCar[] } = await directusService.get(
    `items/cms_new_cars`,
    {
      params: {
        fields: NEW_CARS_FIELDS,
        filter: NEW_CARS_FILTER,
        sort: 'sort',
      },
    },
  );

  for (const currentCar of data) {
    const specifications = await Promise.all(
      currentCar?.specification_prices?.map(async (specification) => {
        const specificationData = await getSpecificationsByDuoportaId(
          specification?.duoporta_model_id,
        );

        return {
          ...specification,
          body_type: mapSpecData('body shape', specificationData),
          fuel_type: mapSpecData('fuel type', specificationData),
          fuel_consumption: mapSpecData(
            'fuel consumption: average',
            specificationData,
          ),
          fuel_capacity: mapSpecData(
            'fuel tank capacity (incl reserve)',
            specificationData,
          ),
          fuel_emissions: mapSpecData(
            'CO2 emissions: average',
            specificationData,
          ),
          doors: mapSpecData('doors', specificationData),
          drive_train: mapSpecData('driven wheels', specificationData),
          transmission: mapSpecData('transmission type', specificationData),
        };
      }),
    );

    currentCar.specification_prices = specifications;
  }

  return data;
});

function mapSpecData(
  field: string,
  specificationData: DuoportaSpecificationDTO[],
) {
  return specificationData.find(
    (spec) => spec.name.toLowerCase() === field.toLowerCase(),
  )?.value;
}

export const newCarsResponse = (cars: any[]) => {
  return cars.map((car) => ({
    newCarsName: car.main_title,
    link: `/new-cars/kia-${car.main_title.split(' ').join('_').toLowerCase()}`,
    id: car.id,
  }));
};

export const mapNewCarItemResponse = (item: any, specificationData: any[]) => ({
  id: item.id,
  mainTitle: item.main_title || null,
  mainBg: getImageLink(item.main_background) || null,
  vehiclePreview: getImageLink(item.vehicle_preview) || null,
  image_no_background_left_angle:
    getImageLink(item.image_no_background_left_angle) || null,
  bodyType: item.body_type || null,
  priceFrom: item.price_from || null,
  introSub: item.intro_subheading || null,
  introTitle: item.intro_title || null,
  introDesc: item.intro_description || null,
  introItems: item.intro_columns || [],
  imagesTitle: item.images_title || null,
  imagesBanner: getImageLink(item.images_banner) || null,
  imagesFuelCon: item.images_fuel_consumption || null,
  imagesWarranty: item.images_warranty || null,
  imagesEmission: item.images_emmission || null,
  co2Emissions: item.co2_emissions || null,
  imagesSmall: item.images_small || [],
  youtubeVideoLink: item.youtube_video_link || null,

  hybridTitle: item.hybrid_title || null,
  hybridColsTop: item.hybrid_technology_top || [],
  hybridBannerTitle: item.hybrid_banner_title || null,
  hybridBbanner: item.hybrid_banner ? getImageLink(item.hybrid_banner) : '',
  hybridTechnologyBottom: item.hybrid_technology_bottom || [],

  featuresColumns: item.features_columns || [],
  colours: item.colour || [],
  logoForBrand: getImageLink(item.logo_for_brand) || null,
  details: item.details || [],
  // specification: newCarsSpecifications(item.specification || []) || [],
  discDataSpec:
    specificationData?.length > 0
      ? specificationData?.map((data) =>
          mapDuoportaData(data, item?.vehicle_preview),
        )
      : null,
  detailsSection: {
    bigImage: getImageLink(item.big_image) || null,
    smallImage: getImageLink(item.small_image) || null,
    linkForButtonSmallImage: item.link_for_button_small_image || null,
    isBigImageLink: item.is_big_image_link || false,
    nameForButtonSmallImage: item.name_for_button_small_image || null,
    titleForSmallImage: item.title_for_small_image || null,
    linkForButtonBigImage: item.link_for_button_big_image || null,
    nameOfButtonBigImage: item.name_of_button_big_image || null,
    titleForBigImage: item.title_for_big_image || null,
  },
  gallery: {
    gallery_interior: item.gallery_interior || [],
    gallery_exterior: item.gallery_exterior || [],
  },
  source: item?.source || null,
  structured_data_faq: item?.structured_data_faq,
  url: item?.url || '',
  status: item?.status || '',
  peerview: {
    itemId: item?.peerview_item_id || null,
    type: item?.peerview_type || null,
  },
});

export const newCarsSpecifications = (specification: any) => {
  const arr: any[] = [];

  specification.map((el: any) => {
    const fuel = getObjectKeys(el, 'fuel');
    const wheels = getObjectKeys(el, 'wheels');
    const feature = getObjectKeys(el, 'feature');
    const engine = getObjectKeys(el, 'engine');
    const transmission = getObjectKeys(el, 'transmission');
    const { mainObj: newEngine, childObj: engineDrivetrain } = engineFn(
      engine,
      'drivetrain',
    );
    const { childObj: wheelsTyres } = engineFn(wheels, 'tyres');

    const data = {
      fuel,
      wheelsTyres,
      feature,
      engine: newEngine,
      transmission,
      engineDrivetrain,
      data: {
        name: el.name,
        price: el.price,
        image: el.image_id,
      },
    };

    return arr.push(data);
  });

  return arr;
};

const getValueByFieldName = (
  specification: { specification_data: { name: string; value: any }[] },
  name: string,
) => {
  return (
    specification?.specification_data?.find((item: any) => item.name === name)
      ?.value ?? null
  );
};

export const mapDuoportaData = (
  specification: {
    title: string;
    price: any;
    specification_data: { name: string; value: any }[];
  },
  image: any,
) => {
  return {
    Overview: {
      'Intro/Revision Date': getValueByFieldName(
        specification,
        duoportaSpecificationNames.introDate,
      ),
      'End Date': formateDate('0'),
      Brand: 'KIA',
      Range: getValueByFieldName(
        specification,
        duoportaSpecificationNames.range,
      ),
      Model: getValueByFieldName(
        specification,
        duoportaSpecificationNames.model,
      ),
      'Range + Model': getValueByFieldName(
        specification,
        duoportaSpecificationNames.rangeModel,
      ),
      'Manufacturer Model Code': getValueByFieldName(
        specification,
        duoportaSpecificationNames.manufacturerModelCode,
      ),
      Type: getValueByFieldName(
        specification,
        duoportaSpecificationNames.bodyShape,
      ),
      Doors: getValueByFieldName(
        specification,
        duoportaSpecificationNames.doors,
      ),
      'Load Volume/Capacity': getValueByFieldName(
        specification,
        duoportaSpecificationNames.loadVolumeCapacity,
      ),
      'MM Code': getValueByFieldName(
        specification,
        duoportaSpecificationNames.mmCode,
      ),
    },
    Engine: {
      Cylinders: getValueByFieldName(
        specification,
        duoportaSpecificationNames.cylinders,
      ),
      'Cylinder Layout': getValueByFieldName(
        specification,
        duoportaSpecificationNames.cylinderLayout,
      ),
      'Cylinder Layout + Quantity': getValueByFieldName(
        specification,
        duoportaSpecificationNames.cylinderLayoutQuantity,
      ),
      'Engine Position': getValueByFieldName(
        specification,
        duoportaSpecificationNames.enginePositionLocation,
      ),
      'Engine Capacity': getValueByFieldName(
        specification,
        duoportaSpecificationNames.engineCapacityCc,
      ),
      'Engine Size (Litre)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.engineSizeLitre,
      ),
      'Engine Detail': getValueByFieldName(
        specification,
        duoportaSpecificationNames.engineDetail,
      ),
      'Fuel Type': getValueByFieldName(
        specification,
        duoportaSpecificationNames.fuelType,
      ),
      Charger: getValueByFieldName(
        specification,
        duoportaSpecificationNames.charger,
      ),
      Supercharger: getValueByFieldName(
        specification,
        duoportaSpecificationNames.supercharger,
      ),
      Turbocharger: getValueByFieldName(
        specification,
        duoportaSpecificationNames.turbocharger,
      ),
      Hybrid: getValueByFieldName(
        specification,
        duoportaSpecificationNames.hybrid,
      ),
      'Power Maximum': getValueByFieldName(
        specification,
        duoportaSpecificationNames.powerMaximum,
      ),
      'Power Maximum (detail)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.powerMaximumDetail,
      ),
      'Power Peak Revs': getValueByFieldName(
        specification,
        duoportaSpecificationNames.powerPeakRevs,
      ),
      'Torque Peak Revs': getValueByFieldName(
        specification,
        duoportaSpecificationNames.torquePeakRevs,
      ),
      'Torque Maximum (total)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.torqueMaximumTotal,
      ),
      'Torque Maximum (detail)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.torqueMaximumDetail,
      ),
    },
    Performance: {
      'Acceleration 0-100 (km/h)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.acceleration0100KmH,
      ),
      'Top Speed (km/h)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.maximumTopSpeed,
      ),
      'Driven Wheels': getValueByFieldName(
        specification,
        duoportaSpecificationNames.drivenWheels,
      ),
      'Driven Wheels Quantity': getValueByFieldName(
        specification,
        duoportaSpecificationNames.drivenWheelsQuantity,
      ),
      'Gear Ratios (Quantity)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.gearRatiosQuantity,
      ),
      'Low Range': getValueByFieldName(
        specification,
        duoportaSpecificationNames.lowRange,
      ),
      Gearshift: getValueByFieldName(
        specification,
        duoportaSpecificationNames.gearshift,
      ),
      'Transmission Type': getValueByFieldName(
        specification,
        duoportaSpecificationNames.transmissionType,
      ),
      'Transmission Name': getValueByFieldName(
        specification,
        duoportaSpecificationNames.transmissionName,
      ),
      'Gearshift Paddles': getValueByFieldName(
        specification,
        duoportaSpecificationNames.gearshiftPaddles,
      ),
    },
    Economy: {
      'CO2 Emmision': getValueByFieldName(
        specification,
        duoportaSpecificationNames.co2EmissionsAverage,
      ),
      'Fuel Consumption: Urban': getValueByFieldName(
        specification,
        duoportaSpecificationNames.fuelConsumptionUrban,
      ),
      'Fuel Consumption: Extra-urban': getValueByFieldName(
        specification,
        duoportaSpecificationNames.fuelConsumptionExtraUrban,
      ),
      'Fuel Consumption: Average': getValueByFieldName(
        specification,
        duoportaSpecificationNames.fuelConsumptionAverage,
      ),
      'Fuel Range: Average': getValueByFieldName(
        specification,
        duoportaSpecificationNames.fuelRangeAverage,
      ),
    },
    Safety: {
      'Limited-Slip Diff': getValueByFieldName(
        specification,
        duoportaSpecificationNames.limitedSlipDiff,
      ),
      'Diff Lock - Front': getValueByFieldName(
        specification,
        duoportaSpecificationNames.diffLockFront,
      ),
      'Diff Lock - Centre': getValueByFieldName(
        specification,
        duoportaSpecificationNames.diffLockCentre,
      ),
      'Diff Lock - Rear': getValueByFieldName(
        specification,
        duoportaSpecificationNames.diffLockRear,
      ),
      'Traction Control': getValueByFieldName(
        specification,
        duoportaSpecificationNames.tractionControl,
      ),
      'Stability Control': getValueByFieldName(
        specification,
        duoportaSpecificationNames.stabilityControl,
      ),
      'Hill Descent Control / Downhill Brake Control': getValueByFieldName(
        specification,
        duoportaSpecificationNames.hillDescentControlDownhillBrakeControl,
      ),
      'Driver Airbag': getValueByFieldName(
        specification,
        duoportaSpecificationNames.driverAirbag,
      ),
      'Front Passenger Airbag': getValueByFieldName(
        specification,
        duoportaSpecificationNames.frontPassengerAirbag,
      ),
      'Driver Knee Airbag': getValueByFieldName(
        specification,
        duoportaSpecificationNames.driverKneeAirbag,
      ),
      'Passenger Knee Airbag': getValueByFieldName(
        specification,
        duoportaSpecificationNames.passengerKneeAirbag,
      ),
      'Front Side Airbags': getValueByFieldName(
        specification,
        duoportaSpecificationNames.frontSideAirbags,
      ),
      'Rear Side Airbags': getValueByFieldName(
        specification,
        duoportaSpecificationNames.rearSideAirbags,
      ),
      'Curtain Airbags': getValueByFieldName(
        specification,
        duoportaSpecificationNames.curtainAirbags,
      ),
      'Airbag Quantity': getValueByFieldName(
        specification,
        duoportaSpecificationNames.airbagQuantity,
      ),
      ABS: getValueByFieldName(
        specification,
        duoportaSpecificationNames.antiLockBrakingSystemAbs,
      ),
      EBD: getValueByFieldName(
        specification,
        duoportaSpecificationNames.electronicBrakeDistributionEbd,
      ),
      BAS: getValueByFieldName(
        specification,
        duoportaSpecificationNames.brakeAssistBasEba,
      ),
      'Central Locking': getValueByFieldName(
        specification,
        duoportaSpecificationNames.centralLocking,
      ),
    },
    Aesthetics: {
      'Seats (quantity)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.seatsQuantity,
      ),
      'electric seat adjustment - driver': getValueByFieldName(
        specification,
        duoportaSpecificationNames.electricSeatAdjustmentDriver,
      ),
      'memory for electric seat adjustment': getValueByFieldName(
        specification,
        duoportaSpecificationNames.memoryForElectricSeatAdjustment,
      ),
      'electric seat adjustment - front passenger': getValueByFieldName(
        specification,
        duoportaSpecificationNames.electricSeatAdjustmentFrontPassenger,
      ),
      'rear seat electric adjustment ': getValueByFieldName(
        specification,
        duoportaSpecificationNames.rearSeatElectricAdjustment,
      ),
      'split rear seat': getValueByFieldName(
        specification,
        duoportaSpecificationNames.splitRearSeat,
      ),
      'folding rear seat': getValueByFieldName(
        specification,
        duoportaSpecificationNames.foldingRearSeat,
      ),
      'sports seats': getValueByFieldName(
        specification,
        duoportaSpecificationNames.sportsSeats,
      ),
      'heated seats - front': getValueByFieldName(
        specification,
        duoportaSpecificationNames.heatedSeatsFront,
      ),
      'ventilated seats - front': getValueByFieldName(
        specification,
        duoportaSpecificationNames.ventilatedSeatsFront,
      ),
      'massaging seats - front': getValueByFieldName(
        specification,
        duoportaSpecificationNames.massagingSeatsFront,
      ),
      'heated seats - rear': getValueByFieldName(
        specification,
        duoportaSpecificationNames.heatedSeatsRear,
      ),
      'ventilated seats - rear': getValueByFieldName(
        specification,
        duoportaSpecificationNames.ventilatedSeatsRear,
      ),
      'massaging seats - rear': getValueByFieldName(
        specification,
        duoportaSpecificationNames.massagingSeatsRear,
      ),
      'ISOFIX Child seat mountings': getValueByFieldName(
        specification,
        duoportaSpecificationNames.isofixChildSeatMountings,
      ),
      'Rear Spoiler': getValueByFieldName(
        specification,
        duoportaSpecificationNames.rearSpoiler,
      ),
    },
    Specifications: {
      'wheels/rims': getValueByFieldName(
        specification,
        duoportaSpecificationNames.alloyWheelsRims,
      ),
      'front tyres': getValueByFieldName(
        specification,
        duoportaSpecificationNames.frontTyres,
      ),
      'rear tyres': getValueByFieldName(
        specification,
        duoportaSpecificationNames.rearTyres,
      ),
      'run-flat tyres': getValueByFieldName(
        specification,
        duoportaSpecificationNames.runFlatTyres,
      ),
      'tyre compressor/pump': getValueByFieldName(
        specification,
        duoportaSpecificationNames.tyreCompressorPump,
      ),
      'tyre repair kit': getValueByFieldName(
        specification,
        duoportaSpecificationNames.tyreRepairKit,
      ),
      'sports suspension': getValueByFieldName(
        specification,
        duoportaSpecificationNames.sportsSuspension,
      ),
      'electronically-adjustable suspension': getValueByFieldName(
        specification,
        duoportaSpecificationNames.electronicallyAdjustableSuspension,
      ),
      'air suspension': getValueByFieldName(
        specification,
        duoportaSpecificationNames.airSuspension,
      ),
      length: getValueByFieldName(
        specification,
        duoportaSpecificationNames.length,
      ),
      'width incl mirrors': getValueByFieldName(
        specification,
        duoportaSpecificationNames.widthInclMirrors,
      ),
      height: getValueByFieldName(
        specification,
        duoportaSpecificationNames.height,
      ),
      wheelbase: getValueByFieldName(
        specification,
        duoportaSpecificationNames.wheelbase,
      ),
      'ground clearance minimum-maximum': getValueByFieldName(
        specification,
        duoportaSpecificationNames.groundClearanceMinimumMaximum,
      ),
      'turning circle (wheels - body)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.turningCircleWheelsBody,
      ),
      'load area length': getValueByFieldName(
        specification,
        duoportaSpecificationNames.loadAreaLength,
      ),
      'load aera width': getValueByFieldName(
        specification,
        duoportaSpecificationNames.loadAeraWidth,
      ),
      'load aera height/depth': getValueByFieldName(
        specification,
        duoportaSpecificationNames.loadAeraHeightDepth,
      ),
      'load volume / capacity': getValueByFieldName(
        specification,
        duoportaSpecificationNames.loadVolumeCapacity,
      ),
      'load volume: maximum': getValueByFieldName(
        specification,
        duoportaSpecificationNames.loadVolumeMaximum,
      ),
      'unladen/tare/kerb weight': getValueByFieldName(
        specification,
        duoportaSpecificationNames.unladenTareKerbWeight,
      ),
      'gross weight (GVM)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.grossWeightGvm,
      ),
      'towing capacity - unbraked': getValueByFieldName(
        specification,
        duoportaSpecificationNames.towingCapacityUnbraked,
      ),
      'towing capacity - braked': getValueByFieldName(
        specification,
        duoportaSpecificationNames.towingCapacityBraked,
      ),
      'fuel tank capacity': getValueByFieldName(
        specification,
        duoportaSpecificationNames.fuelTankCapacityInclReserve,
      ),
      'warranty time (years)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.warrantyTimeYears,
      ),
      'warranty time (months)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.warrantyTimeMonths,
      ),
      'warranty distance': getValueByFieldName(
        specification,
        duoportaSpecificationNames.warrantyDistance,
      ),
      'service/maintenance plan': getValueByFieldName(
        specification,
        duoportaSpecificationNames.serviceMaintenancePlan,
      ),
      'plan time (years)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.planTimeYears,
      ),
      'plan distance': getValueByFieldName(
        specification,
        duoportaSpecificationNames.planDistance,
      ),
      'service plan': getValueByFieldName(
        specification,
        duoportaSpecificationNames.servicePlan,
      ),
      'service plan time (years)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.servicePlanTimeYears,
      ),
      'service plan distance': getValueByFieldName(
        specification,
        duoportaSpecificationNames.servicePlanDistance,
      ),
      'maintenance plan': getValueByFieldName(
        specification,
        duoportaSpecificationNames.maintenancePlan,
      ),
      'maintenance plan time (years)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.maintenancePlanTimeYears,
      ),
      'maintenance plan time (months)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.maintenancePlanTimeMonths,
      ),
      'maintenance plan distance': getValueByFieldName(
        specification,
        duoportaSpecificationNames.maintenancePlanDistance,
      ),
      'service interval': getValueByFieldName(
        specification,
        duoportaSpecificationNames.serviceInterval,
      ),
      'service interval distance': getValueByFieldName(
        specification,
        duoportaSpecificationNames.serviceIntervalDistance,
      ),
      'service interval time': getValueByFieldName(
        specification,
        duoportaSpecificationNames.serviceIntervalTime,
      ),
    },
    Features: {
      'Air conditioning': getValueByFieldName(
        specification,
        duoportaSpecificationNames.airConditioning,
      ),
      'climate control': getValueByFieldName(
        specification,
        duoportaSpecificationNames.climateControlAutomaticAirConditioning,
      ),
      'Power Steering': getValueByFieldName(
        specification,
        duoportaSpecificationNames.powerSteering,
      ),
      'Leather Steering Wheel Rim': getValueByFieldName(
        specification,
        duoportaSpecificationNames.leatherSteeringWheelRim,
      ),
      'Steering Wheel Heating': getValueByFieldName(
        specification,
        duoportaSpecificationNames.steeringWheelHeating,
      ),
      'electric steering column adjustment': getValueByFieldName(
        specification,
        duoportaSpecificationNames.electricSteeringColumnAdjustment,
      ),
      'rear-wheel steer': getValueByFieldName(
        specification,
        duoportaSpecificationNames.rearWheelSteer,
      ),
      'multi-function steering wheel controls': getValueByFieldName(
        specification,
        duoportaSpecificationNames.multiFunctionSteeringWheelControls,
      ),
      'lane departure warning': getValueByFieldName(
        specification,
        duoportaSpecificationNames.laneDepartureWarning,
      ),
      'lane change / blind spot warning': getValueByFieldName(
        specification,
        duoportaSpecificationNames.laneChangeBlindSpotWarningAssistMonitor,
      ),
      'head-up display': getValueByFieldName(
        specification,
        duoportaSpecificationNames.headUpDisplay,
      ),
      'night vision': getValueByFieldName(
        specification,
        duoportaSpecificationNames.nightVision,
      ),
      'on-board computer': getValueByFieldName(
        specification,
        duoportaSpecificationNames.onBoardComputerMultiInformationDisplay,
      ),
      'controls interface screen': getValueByFieldName(
        specification,
        duoportaSpecificationNames.controlsInterfaceScreen,
      ),
      navigation: getValueByFieldName(
        specification,
        duoportaSpecificationNames.navigation,
      ),
      'cruise control': getValueByFieldName(
        specification,
        duoportaSpecificationNames.cruiseControl,
      ),
      'active/adaptive cruise control': getValueByFieldName(
        specification,
        duoportaSpecificationNames.activeAdaptiveCruiseControl,
      ),
      Bluetooth: getValueByFieldName(
        specification,
        duoportaSpecificationNames.bluetoothConnectivity,
      ),
      'CD player': getValueByFieldName(
        specification,
        duoportaSpecificationNames.cdPlayer,
      ),
      'DVD reader': getValueByFieldName(
        specification,
        duoportaSpecificationNames.dvdReader,
      ),
      'rear audio/media entertainment system': getValueByFieldName(
        specification,
        duoportaSpecificationNames.rearAudioMediaEntertainmentSystem,
      ),
      'aux in (auxiliary input jack)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.auxInAuxiliaryInputJack,
      ),
      'USB port': getValueByFieldName(
        specification,
        duoportaSpecificationNames.usbPort,
      ),
      'central locking': getValueByFieldName(
        specification,
        duoportaSpecificationNames.centralLocking,
      ),
      'remote central locking': getValueByFieldName(
        specification,
        duoportaSpecificationNames.remoteCentralLocking,
      ),
      'selective unlocking': getValueByFieldName(
        specification,
        duoportaSpecificationNames.selectiveUnlocking,
      ),
      'keyless access+start / hands-free key': getValueByFieldName(
        specification,
        duoportaSpecificationNames.keylessAccessStartHandsFreeKey,
      ),
      'child-proof/safety lock': getValueByFieldName(
        specification,
        duoportaSpecificationNames.childProofSafetyLock,
      ),
      'soft-close doors': getValueByFieldName(
        specification,
        duoportaSpecificationNames.softCloseDoors,
      ),
      'electric sliding doors': getValueByFieldName(
        specification,
        duoportaSpecificationNames.electricSlidingDoors,
      ),
      'electric windows': getValueByFieldName(
        specification,
        duoportaSpecificationNames.electricWindows,
      ),
      'sliding cab window': getValueByFieldName(
        specification,
        duoportaSpecificationNames.slidingCabWindow,
      ),
      'rear privacy glass': getValueByFieldName(
        specification,
        duoportaSpecificationNames.rearPrivacyGlass,
      ),
      'heated rear screen / rear demister': getValueByFieldName(
        specification,
        duoportaSpecificationNames.heatedRearScreenRearDemister,
      ),
      'rear screen wiper': getValueByFieldName(
        specification,
        duoportaSpecificationNames.rearScreenWiper,
      ),
      'intermittent wiper': getValueByFieldName(
        specification,
        duoportaSpecificationNames.intermittentWiper,
      ),
      'Rain Sensor': getValueByFieldName(
        specification,
        duoportaSpecificationNames.rainSensorAutoWipers,
      ),
      'autodim interior mirror': getValueByFieldName(
        specification,
        duoportaSpecificationNames.autodimInteriorMirror,
      ),
      'memory for electric-adjust mirrors': getValueByFieldName(
        specification,
        duoportaSpecificationNames.memoryForElectricAdjustMirrors,
      ),
      'heated exterior mirrors': getValueByFieldName(
        specification,
        duoportaSpecificationNames.heatedExteriorMirrors,
      ),
      'autodim exterior mirrors': getValueByFieldName(
        specification,
        duoportaSpecificationNames.autodimExteriorMirrors,
      ),
      'mirror housings finish': getValueByFieldName(
        specification,
        duoportaSpecificationNames.mirrorHousingsFinish,
      ),
      'panoramic roof': getValueByFieldName(
        specification,
        duoportaSpecificationNames.panoramicRoof,
      ),
      'folding roof': getValueByFieldName(
        specification,
        duoportaSpecificationNames.foldingRoof,
      ),
      'vinyl upholstery': getValueByFieldName(
        specification,
        duoportaSpecificationNames.vinylUpholstery,
      ),
      'cloth upholstery': getValueByFieldName(
        specification,
        duoportaSpecificationNames.clothUpholstery,
      ),
      'suede-cloth upholstery': getValueByFieldName(
        specification,
        duoportaSpecificationNames.suedeClothUpholstery,
      ),
      'leather upholstery': getValueByFieldName(
        specification,
        duoportaSpecificationNames.leatherUpholstery,
      ),
      'lumbar support adjustment': getValueByFieldName(
        specification,
        duoportaSpecificationNames.lumbarSupportAdjustment,
      ),
      'wind deflector': getValueByFieldName(
        specification,
        duoportaSpecificationNames.windDeflector,
      ),
      'park distance control (PDC) - front': getValueByFieldName(
        specification,
        duoportaSpecificationNames.parkDistanceControlPdcFront,
      ),
      'park distance control (PDC) - rear': getValueByFieldName(
        specification,
        duoportaSpecificationNames.parkDistanceControlPdcRear,
      ),
      'camera for park distance control': getValueByFieldName(
        specification,
        duoportaSpecificationNames.cameraForParkDistanceControl,
      ),
      'surround / top / area view cameras': getValueByFieldName(
        specification,
        duoportaSpecificationNames.surroundTopAreaViewCameras,
      ),
      'park assist (self-parking)': getValueByFieldName(
        specification,
        duoportaSpecificationNames.parkAssistSelfParking,
      ),
      'electric tailgate/bootlid': getValueByFieldName(
        specification,
        duoportaSpecificationNames.electricTailgateBootlid,
      ),
      'roof rails': getValueByFieldName(
        specification,
        duoportaSpecificationNames.roofRails,
      ),
      'rear spoiler': getValueByFieldName(
        specification,
        duoportaSpecificationNames.rearSpoiler,
      ),
      'towbar / trailer hitch': getValueByFieldName(
        specification,
        duoportaSpecificationNames.towbarTrailerHitch,
      ),
    },
    data: {
      name: specification?.title || null,
      price: specification?.price || 0,
      image,
    },
  };
};

export const formateDate = (date: string) => {
  if (date == '0') return 'Present';
  const dateStr = '' + date;

  return `${dateStr.slice(4)}/${dateStr.slice(0, 4)}`;
};

const engineFn = (obj: any, type: string) => {
  return {
    mainObj: getObjectKeys(obj, type, false),
    childObj: getObjectKeys(obj, type),
  };
};

export const getObjectKeys = (objects: any[], name: string, rule = true) => {
  const newObj = {};

  for (const key in objects) {
    const arrNames = key.split('_');
    const newKey = key.replace(`${name}_`, '');

    if (ruleFn(arrNames[0], name, rule)) {
      (newObj as any)[newKey] = objects[key];
    }
  }

  return newObj;
};

const ruleFn = (item: any, name: string, rule: boolean) => {
  return rule ? item === name : item !== name;
};

export const getNewCarIdsList = limiter.wrap(async () => {
  const { data }: any = await directusService.get('/items/cms_new_cars', {
    params: {
      fields: ['id'],
    },
  });

  const paths = data.map(({ id }: any) => ({
    params: { id: id.toString() },
  }));

  return { paths };
});

export const getModelsPaths = limiter.wrap(async () => {
  const { data } = (await directusService.get('items/cms_new_cars', {
    params: { fields: ['main_title'] },
  })) as { data: { main_title: string }[] };

  const paths = data.map(({ main_title }) => ({
    params: { model: main_title.split(' ').join('_').toLowerCase() },
  }));

  return { paths };
});

export const getNewCarDetailsOverview = limiter.wrap(
  async (filter?: Record<string, Filter>): Promise<NewCarOverview[]> => {
    const { data }: { data: NewCarOverview[] } = await directusService.get(
      '/items/cms_new_cars',
      {
        params: {
          fields: NEW_CARS_FIELDS_FOR_OVERVIEW,
          filter: {
            ...NEW_CARS_FILTER,
            ...filter,
          },
          sort: 'sort',
        },
      },
    );

    return data;
  },
);

export const getNewCarItem = limiter.wrap(async (model) => {
  const { data } = (await directusService.get(`/items/cms_new_cars`, {
    params: {
      fields: NEW_CARS_LIST_FIELDS.concat('*'),
      search: model,
    },
  })) as {
    data: any[];
  };

  const currentCar = data.find(
    (item) => item.main_title.split(' ').join('_').toLowerCase() === model,
  );

  if (!currentCar) {
    return null;
  }

  const specifications = await Promise.all(
    currentCar?.specification_prices?.map(async (specification: any) => {
      const specificationDetails = {};
      const specificationData = await getSpecificationsByDuoportaId(
        specification?.duoporta_model_id,
      );
      const featureData = await getFeaturesByDuoportaId(
        specification?.duoporta_model_id,
      );

      specificationDetails;
      return [...specificationData, ...featureData];
    }),
  );

  const specificationData = currentCar?.specification_prices?.map(
    (specification: any, index: number) => {
      return {
        title: specification.title,
        price: specification.price,
        specification_id: specification.specification_id,
        mm_code: specification.mm_code,
        duoporta_model_id: specification.duoporta_model_id,
        year: specification.year,
        specification_data: specifications[index],
      };
    },
  );

  const itemData = mapNewCarItemResponse(currentCar, specificationData);
  const panelDetails = currentCar?.specification_prices.reduce(
    (result: any, cur: any, currentIndex: number) => {
      return {
        ...result,
        [cur.duoporta_model_id]: itemData.discDataSpec?.[currentIndex],
      };
    },
    {},
  );

  return { itemData, panelDetails };
});

export const getSpecificationsByDuoportaId = async (
  id: string,
): Promise<DuoportaSpecification[]> => {
  try {
    const data: DuoportaSpecificationDTO[] = await apiService.get(
      `duoporta/specifications/${id}`,
    );

    return data.map((specification) =>
      mapDuoportaSpecificationData(specification),
    );
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getFeaturesByDuoportaId = async (
  id: string,
): Promise<DuoportaFeature[]> => {
  try {
    const data: DuoportaFeatureDTO[] = await apiService.get(
      `duoporta/features/${id}`,
    );

    return data.map((feature) => mapDuoportaFeatures(feature));
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getSeoTitle = (data: any) => {
  const title = `KIA ${data.mainTitle} - New Kia ${data.mainTitle} ${FOR_SALE} ${KIA_RETAIL_GROUP}`;

  return title;
};

/**
 * @param itemData data from getNewCarItem
 * @returns any DirectusFile objects attached to the image Id's of the vechile
 */
export const getNewCarImageObjects = limiter.wrap(async (itemData) => {
  const imageIds = [
    itemData?.mainBg,
    itemData?.vehiclePreview,
    itemData?.logoForBrand,
    itemData?.detailsSection?.bigImage,
    itemData?.detailsSection?.smallImage,
    itemData?.introItems?.map((item: any) => item.image),
    itemData?.imagesBanner,
    itemData?.imagesSmall?.map((smallImg: any) => smallImg.image),
    itemData?.colours?.map(
      (colour: any) => colour.cms_new_vehicles_colours_id.image,
    ),
    itemData?.hybridColsTop?.map((hybridTech: any) => hybridTech.image),
    itemData?.hybridBbanner,
    itemData?.hybridTechnologyBottom?.map(
      (hybridTech: any) => hybridTech.image,
    ),
    itemData?.featuresColumns?.map(
      (feature: any) => feature.image_text_m2m_id.image,
    ),
  ]
    .filter((idOrUrl) => idOrUrl)
    .flatMap((idOrUrl) => idOrUrl)
    .map((idOrUrl) => getImageId(idOrUrl));

  const imageObjects: DirectusFile[] = await getFileObjectsChunked(imageIds);

  return imageObjects;
});

export const getSelectedNewVehicle = limiter.wrap(async (model: string) => {
  const { data }: any = await directusService.get(`/items/cms_new_cars`, {
    params: {
      filter: { main_title: { _eq: `${model}` } },
      fields: [
        'images_banner',
        'big_image',
        'intro_columns',
        'small_image',
        'gallery_exterior',
        'gallery_interior',
      ],
    },
  });

  return data;
});
export const getSelectedUsedVehicle = limiter.wrap(async (special) => {
  const { data }: any = await directusService.get('items/cms_new_cars');
  const matchedUsedCarObject = data.find((item: any) => {
    return item.specification_prices.some(
      (spec: any) => spec.mm_code === special.mmcode,
    );
  });

  return matchedUsedCarObject;
});

/**
 * getNewCarItems is for the model page to find all published vehicles and cache them on page
 */
export const getNewCarItems = async (
  filter?: Record<string, Filter>,
  applyDefaultFilter: boolean = true,
): Promise<NewCarData[]> => {
  const appliedFilter = applyDefaultFilter
    ? { ...filter, ...NEW_CARS_FILTER }
    : filter;

  const { data }: { data: NewCarData[] } = await directusService.get(
    `/items/cms_new_cars`,
    {
      params: {
        fields: NEW_CARS_MODEL_FIELDS,
        filter: appliedFilter,
      },
    },
  );

  return data;
};

export const getNewCarTitlesByMMCode = async (
  mmcode: string,
): Promise<{ main_title: string; brochure?: string }[]> => {
  const { data } = await directusService.get(`/items/cms_new_cars`, {
    params: {
      fields: 'main_title, brochure',
      filter: {
        spec_variant_specs: {
          mm_code: {
            mmcode: {
              _eq: mmcode,
            },
          },
        },
      },
    },
  });

  return data;
};
